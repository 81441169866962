import React,{Suspense} from 'react';
import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";

import { OutlineButton } from '../components/button/Button';

import PropagateLoader from "react-spinners/PropagateLoader";
import Adcomp from '../ads/Adcomp';
import NativeBanner from './NativeBanner';
import { width } from '@mui/system';
const HeroSlide=React.lazy(()=>import('../components/hero-slide/HeroSlide'));
const MovieList=React.lazy(()=>import('../components/movie-list/MovieList'));
const Home = () => {
    return (
        <>
        <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
            <HeroSlide category='movie'/>
            </Suspense>
            

             {/*Running Notice */}
             {/* <div >
                <b>
             <Marquee gradientColor={[199, 197, 193]} gradient ={true} gradientWidth={window.innerWidth/8} speed={28} style={{color:'#e7f266',cursor:'pointer',fontSize:'15px'} }>
                 <a href="https://link.showflix.in/androidapp" target="_blank" rel="noopener noreferrer">🔗 Click to get updated Showflix 📱Android App V7.0🔗 </a>
                </Marquee>
                </b>
             </div>
             <br></br>
             <br></br> */}
            <div className="container">
                
                   {/*Recently Uploaded*/}
                   <div className="section mb-3">                                                     
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Recently Uploaded</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"toprated"}/></Suspense>
                </div>

                {/*TrendingMovie*/}
                <div className="section mb-3">                                                      
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Trending Movies</h2>
                        <Link to="/movie">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"latest"}/></Suspense>
                </div>

                 {/*Oscars Winners*/}
                {/* <div className="section mb-3">                                                          
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Oscar Winners 🎉🎉</h2>
                        <Link to="/lan/movie/Oscars">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Oscars"}/></Suspense>
                </div> */}

                {/*Tamil Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}} >Tamil Movies</h2>
                        <Link to="/lan/movie/Tamil">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Tamil"}/></Suspense>
                </div>

                 {/*Telugu Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Telugu Movies</h2>
                        <Link to="/lan/movie/Telugu">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Telugu"}/></Suspense>
                </div>

                 {/*Marvel Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Marvel Movies</h2>
                        <Link to="/lan/movie/Marvel">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Marvel"}/></Suspense>
                </div>

                 {/*Tamil Dubbed Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Tamil Dubbed Movies</h2>
                        <Link to="/lan/movie/Tamil Dubbed">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Tamil Dubbed"}/></Suspense>
                </div>
              
                {/*English Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}} >English Movies</h2>
                        <Link to="/lan/movie/English">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"English"}/></Suspense>
                </div>
               
                {/*Hindi Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}} >Hindi Movies</h2>
                        <Link to="/lan/movie/Hindi">
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div> <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Hindi"}/></Suspense>
                </div>
               
                {/*Malayalam Movies*/}
                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2 style={{borderWidth:'5px',borderStyle:'double',borderColor:'#00FFD1',borderRadius:'10px',paddingLeft:'8px',paddingRight:'8px'}}>Malayalam Movies</h2>
                        <Link to="/lan/movie/Malayalam" >
                            <OutlineButton className="small">View more ➤</OutlineButton>
                        </Link>
                    </div>
                    <Suspense fallback={<div><PropagateLoader></PropagateLoader></div>}>
                    <MovieList category={"movie"} type={"Malayalam"}/></Suspense>
                </div>

                
                {/* <Adcomp id='343753'></Adcomp>mobile */}
                {/* <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Trending TV</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.tv} type={tvType.popular}/>
                </div>

                <div className="section mb-3">
                    <div className="section__header mb-2">
                        <h2>Top Rated TV</h2>
                        <Link to="/tv">
                            <OutlineButton className="small">View more</OutlineButton>
                        </Link>
                    </div>
                    <MovieList category={category.tv} type={tvType.top_rated}/>
                </div> */}
            </div>
        </>
    );
}

export default Home;
